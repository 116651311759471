import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  ColumnSort,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  TableState,
  useReactTable,
} from '@tanstack/react-table';
import omit from 'lodash/omit';
import hash from 'object-hash';
import type { FieldValues } from 'react-hook-form';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ButtonAction, Resource } from '@/classes';
import ActionsRenderer from '@/components/Actions/ActionsRenderer';
import { DataTableContext } from '@/components/DataTable/DataTableContext';
import ReactTable from '@/components/DataTable/ReactTable';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import useGetParamsForIndexRequest from '@/hooks/useGetParamsForIndexRequest';
import useOnChangeEffect from '@/hooks/useOnChangeEffect';
import usePushWithContext, { MappedRow } from '@/hooks/usePushWithContext';
import getApiUrl from '@/utils/getApiUrl';
import numString from '@/utils/numString';

export interface DataTableHandle {
  onReload: () => void;
}

export interface DataTableProps<T extends FieldValues = FieldValues> {
  resource: Resource<T>;
  getHref?: (row: T) => string;
  onEdit?: (row: T) => void;
  initialState?: Partial<TableState>;
  onStateUpdated?: (s: TableState) => void;
}

function stringToColumnSort(sort: string): ColumnSort {
  return {
    id: sort.replace(/^-/, ''),
    desc: sort.startsWith('-'),
  };
}

function columnSortToString(sort: ColumnSort): string {
  return `${sort.desc ? '-' : ''}${sort.id}`;
}

function DataTable<T extends FieldValues = FieldValues>(
  props: DataTableProps<T>,
  ref: ForwardedRef<DataTableHandle>,
) {
  const { resource, getHref, onEdit, initialState = {}, onStateUpdated } = props;
  const getParamsForIndexRequest = useGetParamsForIndexRequest();
  const { confirm } = useDialogs();
  const showLoading = useShowLoading();

  const columns = useMemo(() => resource.columns.map((c) => c.forReactTable()), [resource]);

  const table = useReactTable({
    data: [] as T[],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    enableGlobalFilter: true,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    columnResizeMode: 'onChange',
    getRowId: (row) => row[resource.primaryKey],
    initialState: {
      globalFilter: '',
      rowSelection: {},
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
      columnVisibility: resource.columns.reduce(
        (acc, col) => {
          acc[col.name] = resource.getInitialTableColumnNames().includes(col.name);
          return acc;
        },
        {} as Record<string, boolean>,
      ),
      columnFilters: Object.entries(resource.defaultFilters).map(([id, value]) => ({ id, value })),
      sorting: resource.defaultSort ? [stringToColumnSort(resource.defaultSort)] : [],
      ...initialState,
    },
  });

  const storageKey = `dataTableState.${resource.key}.${hash(resource.getColumnNames())}`;
  const [localState, setState] = useState(() => {
    const stored = localStorage.getItem(storageKey);
    if (stored) {
      return {
        ...table.initialState,
        ...omit(JSON.parse(stored), ['rowSelection', 'pagination']),
      };
    }
    return table.initialState;
  });
  const [pagination, setPagination] = useQueryParams({
    pageIndex: withDefault(NumberParam, 0),
    pageSize: withDefault(NumberParam, 25),
  });
  const [globalFilter, setGlobalFilter] = useQueryParam('query', StringParam, {
    updateType: 'replaceIn',
  });
  const [sort, setSort] = useQueryParam('sort', StringParam);

  const state: TableState = useMemo(() => {
    return {
      ...localState,
      sorting: sort ? [stringToColumnSort(sort)] : localState.sorting,
      globalFilter,
      pagination,
    };
  }, [localState, sort, globalFilter, pagination]);

  useOnChangeEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(localState));
  }, [localState]);

  useEffect(() => {
    if (onStateUpdated) {
      onStateUpdated(state);
    }
  }, [state]);

  const requestParams = getParamsForIndexRequest(resource, state);
  const query = useQuery(['dataTable', resource.key, JSON.stringify(requestParams)], () =>
    resource.getIndexRequest(requestParams),
  );
  const { data, isFetching, isError, refetch } = query;
  const records = useMemo(() => data?.data.data || [], [data]);
  const total = data?.data.meta.total;

  useImperativeHandle(ref, () => ({
    onReload: refetch,
  }));

  const otherRows: MappedRow[] = getHref
    ? records.map((row) => ({
        label: resource.getTitle(row),
        key: row[resource.primaryKey],
        href: getHref(row),
      }))
    : [];
  const pushWithContext = usePushWithContext(otherRows);

  const getOnRowClick = () => {
    if (onEdit) {
      return onEdit;
    }
    if (getHref) {
      return (n: T) => pushWithContext(getHref(n));
    }
    return undefined;
  };

  const getUrlForExport = () => {
    const params = getParamsForIndexRequest(resource, state);
    const searchParams = new URLSearchParams({
      ...(params as Record<string, string>),
      format: 'xlsx',
    });
    return getApiUrl(resource.getApiEndpoint(), searchParams);
  };

  const handleDelete = () => {
    const selected = table.getSelectedRowModel().rows.map((r) => r.original[resource.primaryKey]);
    if (selected.length === 0) {
      return;
    }
    confirm({
      title: `Delete ${
        selected.length === 1 ? resource.singularName : numString(selected.length, resource.name)
      }`,
      description: 'Are you sure? This action cannot be undone.',
    }).then(() => {
      showLoading(Promise.all(selected.map((id) => resource.getDeleteRequest(id)))).then(() => {
        refetch();
      });
    });
  };

  const actionsArray = Array.isArray(resource.bulkActions)
    ? [...resource.bulkActions]
    : resource.bulkActions;

  if (resource.deletable && Array.isArray(actionsArray)) {
    actionsArray.push(new ButtonAction('Delete', handleDelete, Delete));
  }

  table.setOptions((prev) => ({
    ...prev,
    data: records,
    rowCount: total,
    enableRowSelection: actionsArray.length > 0,
    state,
    onSortingChange: (updater) => {
      const newSort = typeof updater === 'function' ? updater(table.getState().sorting) : updater;
      setSort(newSort.length > 0 ? columnSortToString(newSort[0]!) : undefined);
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onStateChange: setState,
    meta: {
      // @ts-expect-error This is ok
      getSum: (columnId: string) => data?.data.meta[`${columnId}_sum`],
      // @ts-expect-error This is ok
      getAvg: (columnId: string) => data?.data.meta[`${columnId}_avg`],
    },
  }));

  if (isError) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" onClick={() => refetch()}>
            Retry
          </Button>
        }
      >
        An error occurred while loading {resource.name}.
      </Alert>
    );
  }

  return (
    <DataTableContext.Provider value={{ table, query }}>
      <ReactTable
        table={table}
        isFetching={isFetching}
        refetch={refetch}
        filterable={resource.getFilterableFields()}
        getBulkActions={() => <ActionsRenderer actions={actionsArray} resource={resource} />}
        onRowClick={getOnRowClick()}
        onDownload={resource.canExport ? () => window.open(getUrlForExport()) : undefined}
      />
    </DataTableContext.Provider>
  );
}

export default forwardRef(DataTable) as <T extends FieldValues = FieldValues>(
  props: DataTableProps<T> & { ref?: ForwardedRef<DataTableHandle> },
) => JSX.Element;
