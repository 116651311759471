import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { InventoryCostLayer, OrderItem, PurchaseOrderItem, SkuResource } from '@/types';

export function useGetVariantMutation() {
  return useMutation((variantId: number) =>
    axios.get<SkuResource>(`/api/variants/${variantId}`).then(({ data }) => data),
  );
}

export function useGetOnOrderItems(variantId: number | undefined) {
  return useQuery(
    ['onOrderItems', variantId],
    () =>
      axios
        .get<{
          po_items: PurchaseOrderItem[];
          order_items: OrderItem[];
        }>(`/api/variants/${variantId}/on-order-items`)
        .then(({ data }) => data),
    {
      enabled: Boolean(variantId),
    },
  );
}

export function useGetVariantsForProduct(productId: number) {
  return useQuery(['otherSizes', productId], () =>
    axios
      .get<{ data: SkuResource[] }>(`/api/variants?filter[product_id]=${productId}&sort=sort`)
      .then(({ data }) => data.data),
  );
}

export function useGetOtherVendorsForVariant(variantId: number | undefined) {
  return useQuery(
    ['otherVendorForVariant', variantId],
    () =>
      axios
        .get<{ data: SkuResource[] }>(`/api/variants/${variantId}/other-vendors`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(variantId),
    },
  );
}

export function useCostLayers(variantId: number | undefined) {
  return useQuery(
    ['inventoryCostLayers', variantId],
    () =>
      axios
        .get<{ data: InventoryCostLayer[] }>(`/api/variants/${variantId}/cost-layers`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(variantId),
    },
  );
}
