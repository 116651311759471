import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import { StockColor, complexityUnitSchema } from '@/types';

export default function stockColors() {
  return new Resource<StockColor>('Stock Colors')
    .withSchema(
      z.object({
        unit: complexityUnitSchema,
        name: z.string().min(1),
        code: z.string().min(1),
        hex: z.string().length(7).nullish(),
        priority: z.coerce.number().min(0).nullish(),
        sort: z.coerce.number().min(0).optional(),
      }),
    )
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      FieldFactory.select('unit', ['colors', 'stiches']).sortable().filterable(),
      FieldFactory.text('name').sortable(),
      FieldFactory.text('code').sortable(),
      FieldFactory.color('hex'),
      FieldFactory.number('priority').sortable(),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ]);
}
