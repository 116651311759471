import { Resource, CardLayout, FieldFactory, GroupLayout } from '@/classes';
import {
  AppResponse,
  DecorationType,
  decorationTypePayloadSchema,
  decorationLocationPayloadSchema,
} from '@/types';

export default function decorationTypes(appConfig: AppResponse) {
  return new Resource<DecorationType>('Decoration Types')
    .withSchema(decorationTypePayloadSchema)
    .withQueryParams({
      single: {
        with: 'locations',
      },
    })
    .withStaticOptions(appConfig.decorationTypes)
    .withFieldsAndColumns([
      new CardLayout('', [
        FieldFactory.text('name').withColumnSpan(3).sortable(),
        FieldFactory.belongsTo('event_type', 'productionEventTypes')
          .withHelp('Leaving this blank results in no production events')
          .withColumnSpan(3)
          .filterable()
          .sortable('event_type.name'),
        FieldFactory.belongsTo('external_event_type', 'productionEventTypes')
          .withHelp('If purchasing the decoration and just applying it in house')
          .withColumnSpan(3)
          .sortable('external_event_type.name'),
        FieldFactory.number('sort')
          .withHelp('Higher numbers show up first')
          .withColumnSpan(3)
          .sortable(),
        new GroupLayout('Settings', [
          FieldFactory.boolean('needs_sew_out', 'Needs Sew Out').sortable(),
          FieldFactory.boolean('has_drops', 'Has Name/Number Drops?').sortable(),
        ]).withColumnSpan(12),
      ]),
      new CardLayout('locations')
        .withoutPadding()
        .withFields([
          FieldFactory.table(
            'locations',
            [
              FieldFactory.text('name'),
              FieldFactory.number('sort').withHelp('Smaller numbers show up first'),
            ],
            decorationLocationPayloadSchema,
          ).withColumnSpan(12),
        ]),
    ]);
}
