import { Resource, FieldFactory } from '@/classes';
import { BoxType, boxTypePayloadSchema } from '@/types';

export default function boxTypes() {
  return new Resource<BoxType>('Box Types')
    .withSchema(boxTypePayloadSchema)
    .setUseDrawer()
    .withFieldsAndColumns([
      FieldFactory.text('name'),
      FieldFactory.number('length'),
      FieldFactory.number('width'),
      FieldFactory.number('height'),
      FieldFactory.number('sort'),
    ]);
}
