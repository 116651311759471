import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import { ShippingMethod } from '@/types';

export default function shippingMethods() {
  return new Resource<ShippingMethod>('Shipping Methods')
    .withSchema(
      z.object({
        name: z.string().min(1),
      }),
    )
    .setUseDrawer()
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ]);
}
